.section {
    padding-right: 10px;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
}

.section::-webkit-scrollbar {
    width: 8px;
}
 
.section::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 100px;
}

.section::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 100px;
    background-color: #ff97af;
    background-clip: content-box;
}

.message {
    margin-bottom: 10px;
}