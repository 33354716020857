.font-size-1-5-em {
    font-size: 1.5em !important;
}
.font-size-0-7-em {
    font-size: 0.7em !important;
}
.font-size-0-85-em {
    font-size: 0.85em !important;
}

.time-picker-table {
    margin: auto;
    width: 100%;
    background-color: #f9f9f9 !important;
    padding: 0px !important;
}
.time-picker-table > div > input {
    padding-bottom: 0px !important;
    margin: auto;
    width: 175px;
}
.right-to-left {
    unicode-bidi: bidi-override;
    direction: RTL;
}
.table-download {
    width: fit-content;
    position: absolute;
    right: 25px;
    top: -40px;
    display: flex;
}
.card-icon-title {
    color: #3c4858;
    text-decoration: none;
    font-weight: 300;
    margin-top: 30px;
    margin-bottom: 25px;
    min-height: 32px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-top: 15px;
    margin-bottom: 0px;
}
.create-task-title {
    width: fit-content;
    display: inline-block;
}

.create-task-button {
    display: flex;
    width: 100%;
    padding: 0px 15px 15px 0px;
    vertical-align: super;
    border-radius: 5px;
}
.checkbox-span-label {
    overflow: hidden;
    width: max-content;
}
.display-flex-direction-inherit {
    flex-direction: inherit !important;
}
.display-flex {
    display: flex !important;
}
.justify-content-space-between {
    justify-content: space-between !important;
}
.display-flex-gow-1 {
    flex-grow: 1 !important;
}
.justify-content-center {
    justify-content: center !important;
}
.users-actions {
    position: absolute;
    top: -45px;
    z-index: 9999;
    right: 50px;
}
.user-analytics-container {
    display: flex;
    flex-direction: row;
}
.user-analytics-card-container {
    width: 100%;
    margin-right: 25px;
}
.user-analytics-second-card {
    width: 100%;
}
.wide-icon {
    width: 1.5em !important;
}
.rose-background {
    background-color: #e91e63 !important;
}
.pac-container {
    z-index: 2147483647 !important;
}
.google-maps-input-container {
    display: flex;
    height: 40px;
    align-items: center;
    margin-top: -2.5px;
}
.google-maps-select {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 40px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    background-color: white;
}
.google-maps-input {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 1em !important;
    outline: none;
    text-overflow: ellipses;
    background-color: white;
}

.custom-action-buttons-font {
    margin-right: 10px;
}

.custom-action-buttons-font .user-action-buttons > div > button,
.custom-action-buttons-font .user-action-buttons > div > span > button {
    width: 18px !important;
    height: 18px !important;
    font-size: 18px !important;
}

.text-center {
    text-align: center;
}
.font-size-075 {
    font-size: 0.75em;
}
.m-t-negative-10 {
    margin-top: -10px;
}
.user-info-header {
    display: flex;
    color: #4c4c4c;
}
.header-buttons-wrapper {
    float: right;
}
.user-info-header-title {
    width: 50%;
    text-transform: uppercase;
}
.user-action-buttons {
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
}

.user-activity-times {
    margin-bottom: 30px;
}

.user-activity-item {
    display: flex;
    flex-direction: row;
}

.custom-list-item-container {
    display: inline-flex;
    align-items: center;
    width: 100%;
}
.custom-list-item {
    padding-right: 5px !important;
}
.custom-list-item-primary {
    font-weight: 500;
    color: #4c4c4c;
    font-size: 1.25em;
    margin-left: 15px;
    margin-right: 10px;
    text-transform: uppercase;
}
.custom-list-item-icon {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    align-items: center;
}
.custom-list-item-icon .material-icons {
    width: 1.25em;
}
.custom-list-item-secondary {
    margin-left: auto;
    text-align: right;
    word-wrap: break-word;
}
.custom-table-header-font .rt-resizable-header-content {
    font-size: 0.75em !important;
}
.custom-table-font {
    font-size: 10px;
}
.task-responses-download-button {
    position: absolute !important;
    top: -50px;
    right: 25px;
}
.modal-close-button {
    float: right;
    margin: 10px;
    padding: 10px;
}
.custom-user-status {
    font-weight: bold !important;
}
.custom-color-dark {
    color: #555555 !important;
}
.custom-color-light-warning {
    color: #ffcc66 !important ;
}
.custom-color-warning {
    color: #fb8c00 !important ;
}
.custom-button-icon-primary {
    color: #276bb0 !important ;
}
.custom-color-light-primary {
    color: #66b3ff !important ;
}
.custom-color-primary {
    color: #276bb0 !important ;
}
.custom-color-light-success {
    color: #00ff55 !important ;
}
.custom-color-success {
    color: #009933 !important ;
}
.custom-color-danger {
    color: #e55b4a !important ;
}

.custom-backgound-color-warning {
    background-color: #fb8c00 !important ;
}
.custom-backgound-color-success {
    background-color: #00bd00 !important ;
}
.custom-backgound-color-danger {
    background-color: #e55b4a !important ;
}
.custom-backgound-color {
    background-color: #e55b4a !important;
    color: white !important;
}
.custom-button-icon-danger {
    color: #e55b4a;
}
.custom-modal {
    top: 50%;
    width: 750px;
    margin: auto;
    outline: none;
    position: absolute;
    background-color: #f9f9f9;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
    min-height: 250px;
    border-radius: 5px;
    max-height: 90vh;
    overflow-y: scroll;
}

@media only screen and (max-height: 800px) {
    .custom-modal {
        overflow-y: scroll;
    }
}

.no-top-padding {
    padding-top: 0 !important;
}
.container-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.h-100 {
    min-height: 100%;
}
.full-height {
    height: 100%;
}
.custom-button {
    margin: 0px 10px 0px 10px;
    padding: 10px;

    flex: 0 0 auto;
    color: rgba(0, 0, 0, 0.54);
    padding: 12px;
    overflow: visible;
    font-size: 1.5rem;
    text-align: center;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    color: inherit;
    border: 0;

    cursor: pointer;
    display: inline-flex;
    outline: none;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    -moz-appearance: none;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}
button.custom-button:disabled > i,
button.custom-button[disabled] > i {
    color: #999999 !important;
}
button.custom-button:disabled,
button.custom-button[disabled] {
    cursor: default;
}

.display-flex {
    display: flex;
    flex-direction: row-reverse;
}
.align-items-center {
    align-items: center;
}
.avatar {
    background-color: white;
    padding: 5px 10px 5px 10px;
    border-radius: 50%;
    color: #80c3d5;
    font-weight: 900;
}
.custom-label {
    font-size: 1.2em;
    display: block;
}
.card-body {
    width: 100%;
    margin: auto;
    display: flex;
    padding: 0px 10px 10px 10px;
}
.card-body-wrap {
    flex-wrap: wrap;
}
.card-borderless {
    box-shadow: none !important;
}
.card-header-buttons {
    display: flex;
    justify-content: flex-end;
    margin-left: 10px;
}
.card-title {
    font-weight: 400;
    font-size: 1.2em;
    padding-left: 10px;
}
.card-header {
    display: flex;

    width: 97.5%;
    margin: auto;
    word-wrap: break-word;
    background-color: #00acc1;
    color: #ffffff;
    border-radius: 4px;
}
.card-header-secondary-color {
    background-color: #88cfdd !important;
}
.card {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    min-width: 0;
    font-size: 0.875rem;
    margin-top: 20px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;
    border-radius: 6px;
}
.centered-text {
    text-align: center;
}
.f-1-em {
    font-size: 1em !important;
}
.f-5-em {
    font-size: 0.5em !important;
}
.f-75-em {
    font-size: 0.75em !important;
}
.f-85-em {
    font-size: 0.85em !important;
}
.f-95-em {
    font-size: 0.95em !important;
}
.p-b-40 {
    padding-bottom: 40px !important;
}
.p-b-20 {
    padding-bottom: 20px !important;
}
.p-t-20 {
    padding-top: 20px !important;
}
.p-t-40 {
    padding-top: 40px !important;
}
.m-b-20 {
    margin-bottom: 20px !important;
}
.m-b-40 {
    margin-bottom: 40px !important;
}
.m-t-40 {
    margin-top: 40px !important;
}
.m-t-50 {
    margin-top: 50px !important;
}
.m-r-10 {
    margin-right: 10px !important ;
}
.m-r-15 {
    margin-right: 15px !important ;
}
.m-r-20 {
    margin-right: 20px !important ;
}
.m-r-25 {
    margin-right: 25px !important ;
}

.m-l-10 {
    margin-left: 10px !important ;
}
.m-l-15 {
    margin-left: 15px !important ;
}
.m-l-20 {
    margin-left: 20px !important ;
}
.m-l-25 {
    margin-left: 25px !important ;
}
.p-r-10 {
    padding-right: 10px !important;
}
.p-r-15 {
    padding-right: 15px !important;
}
.p-r-20 {
    padding-right: 20px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}
.p-l-15 {
    padding-left: 15px !important;
}
.p-l-20 {
    padding-left: 20px !important;
}
.h-w-75 {
    min-height: 75vh;
}
.h-w-70 {
    min-height: 70vh;
}
.h-w-85 {
    height: 85vh;
}
.m-b-0 {
    margin-bottom: 0px !important;
}
.b .m-a {
    margin: auto !important;
}
.m-t-0 {
    margin-top: 0px !important;
}
.m-1 {
    margin: 1px;
}
.m-5 {
    margin: 5px;
}
.m-10 {
    margin: 10px !important;
}
.m-15 {
    margin: 15px !important;
}
.m-20 {
    margin: 20px !important;
}
.p-1 {
    padding: 1px;
}
.p-5 {
    padding: 5px;
}
.p-10 {
    padding: 10px !important;
}
.p-15 {
    padding: 15px !important;
}
.p-20 {
    padding: 20px !important;
}
.pt-20 {
    padding-top: 20px;
}
.p-40 {
    padding: 40px !important;
}
.w-100 {
    width: 100% !important;
}
.w-95 {
    width: 95% !important;
}
.w-75 {
    width: 75% !important;
}
.w-35 {
    width: 35%;
}
.w-65 {
    width: 65% !important;
}
.w-50 {
    width: 50% !important;
}
.w-25 {
    width: 25% !important;
}
.w-80-px {
    width: 80px !important;
}
.w-500-px {
    width: 500px !important ;
}
.custom-error {
    color: #e55b4a !important;
}
.custom-error-border {
    border-bottom: 1px solid #e55b4a !important;
}
.color-white > label.pure-material-checkbox,
.color-white > label.pure-material-checkbox > input {
    color: white !important ;
}
.pure-material-checkbox {
    margin-top: 10px;
    z-index: 0;
    position: relative;
    display: block;
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.87);
    font-family: var(--pure-material-font, 'Roboto', 'Segoe UI', BlinkMacSystemFont, system-ui, -apple-system);
    font-size: 16px;
    line-height: 1.5;
    color: #152742;
}

/* Input */
.pure-material-checkbox > input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    z-index: -1;
    position: absolute;
    left: -10px;
    top: -8px;
    display: block;
    margin: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    box-shadow: none;
    outline: none;
    opacity: 0;
    transform: scale(1);
    pointer-events: none;
    transition: opacity 0.3s, transform 0.2s;
}

/* Span */
.pure-material-checkbox > span {
    display: inline-flex;
    width: 100%;
    cursor: pointer;
}

/* Box */
.pure-material-checkbox > span::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    margin: 3px 11px 3px 1px;
    border: solid 2px; /* Safari */
    border-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
    border-radius: 2px;
    width: 18px;
    height: 18px;
    vertical-align: top;
    transition: border-color 0.2s, background-color 0.2s;
}

/* Checkmark */
.pure-material-checkbox > span::after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 5px;
    border: solid 2px transparent;
    border-right: none;
    border-top: none;
    transform: translate(3px, 4px) rotate(-45deg);
}

/* Checked, Indeterminate */
.pure-material-checkbox > input:checked,
.pure-material-checkbox > input:indeterminate {
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::before,
.pure-material-checkbox > input:indeterminate + span::before {
    border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
    background-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked + span::after,
.pure-material-checkbox > input:indeterminate + span::after {
    border-color: rgb(var(--pure-material-onprimary-rgb, 255, 255, 255));
}

.pure-material-checkbox > input:indeterminate + span::after {
    border-left: none;
    transform: translate(4px, 3px);
}

/* Hover, Focus */
.pure-material-checkbox:hover > input {
    opacity: 0.04;
}

.pure-material-checkbox > input:focus {
    opacity: 0.12;
}

.pure-material-checkbox:hover > input:focus {
    opacity: 0.16;
}

/* Active */
.pure-material-checkbox > input:active {
    opacity: 1;
    transform: scale(0);
    transition: transform 0s, opacity 0s;
}

.pure-material-checkbox > input:active + span::before {
    border-color: rgb(var(--pure-material-primary-rgb, 33, 150, 243));
}

.pure-material-checkbox > input:checked:active + span::before {
    border-color: transparent;
    background-color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.6);
}

/* Disabled */
.pure-material-checkbox > input:disabled {
    opacity: 0;
}

.pure-material-checkbox > input:disabled + span {
    color: rgba(var(--pure-material-onsurface-rgb, 0, 0, 0), 0.38);
    cursor: initial;
}

.pure-material-checkbox > input:disabled + span::before {
    border-color: currentColor;
}

.pure-material-checkbox > input:checked:disabled + span::before,
.pure-material-checkbox > input:indeterminate:disabled + span::before {
    border-color: transparent;
    background-color: currentColor;
}

.select {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.select-text {
    color: #152742;
    position: relative;
    font-family: inherit;
    background-color: transparent;
    width: 100%;
    padding: 10px 10px 5px 0;
    font-size: 18px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Remove focus */
.select-text:focus {
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
}

/* Use custom arrow */
.select .select-text {
    appearance: none;
    -webkit-appearance: none;
}

.select:after {
    position: absolute;
    top: 18px;
    right: 10px;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.12);
    pointer-events: none;
}

/* LABEL ======================================= */
.select-label {
    color: rgba(0, 0, 0, 0.26);
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 10px;
    transition: 0.2s ease all;
}

/* active state */
.select-text:focus ~ .select-label,
.select-text:valid ~ .select-label {
    color: #aaaaaa;
    top: -15px;
    transition: 0.2s ease all;
    font-size: 14px;
}

/* BOTTOM BARS ================================= */
.select-bar {
    position: relative;
    display: block;
    width: 100%;
}

.select-bar:before,
.select-bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #2f80ed;
    transition: 0.2s ease all;
}

.select-bar:before {
    left: 50%;
}

.select-bar:after {
    right: 50%;
}

/* active state */
.select-text:focus ~ .select-bar:before,
.select-text:focus ~ .select-bar:after {
    width: 50%;
}

/* HIGHLIGHTER ================================== */
.select-highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

@keyframes ripple {
    0% {
        box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
    }
    50% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
    100% {
        box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
    }
}
.md-radio {
    margin: 16px 0;
    color: #152742;
}
.md-radio.md-radio-inline {
    display: inline-block;
}
.md-radio input[type='radio'] {
    display: none;
}
.md-radio input[type='radio']:checked + label:before {
    border-color: #337ab7;
}
.md-radio input[type='radio']:checked + label:after {
    transform: scale(1);
}
.md-radio label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 30px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    color: #152742;
    overflow: hidden;
}
.md-radio label:before,
.md-radio label:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    transition: all 0.3s ease;
    transition-property: transform, border-color;
}
.md-radio label:before {
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid rgba(0, 0, 0, 0.54);
}
.md-radio label:after {
    top: 5px;
    left: 5px;
    width: 10px;
    height: 10px;
    transform: scale(0);
    background: #337ab7;
}

/* input */
.group {
    position: relative;
    margin-top: 25px;
}
.group > input {
    width: 100%;
    color: #152742;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 1px solid #757575;
}
.group > input:focus {
    outline: none;
}

/* LABEL ======================================= */
.group > label {
    color: #aaaaaa;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

/* active state */
.group > input:focus ~ label,
.group > input:valid ~ label {
    top: -15px;
    font-size: 14px;
    color: #aaaaaa;
}

/* BOTTOM BARS ================================= */
.bar {
    position: relative;
    display: block;
    width: 100%;
}
.bar:before,
.bar:after {
    content: '';
    height: 1px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #5264ae;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}
.bar:before {
    left: 50%;
}
.bar:after {
    right: 50%;
}

/* active state */
.group > input:focus ~ .bar:before,
.group > input:focus ~ .bar:after {
    width: 50%;
}

/* HIGHLIGHTER ================================== */

/* active state */
.group > input:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from {
        background: #5264ae;
    }
    to {
        width: 0;
        background: transparent;
    }
}
@-moz-keyframes inputHighlighter {
    from {
        background: #5264ae;
    }
    to {
        width: 0;
        background: transparent;
    }
}
@keyframes inputHighlighter {
    from {
        background: #5264ae;
    }
    to {
        width: 0;
        background: transparent;
    }
}
